<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-card width="320">
        <v-card-text>
          <v-text-field v-model="user" label="User" prepend-icon="mdi-account" />
          <v-text-field v-model="password" label="Password" prepend-icon="mdi-lock" type="password" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="disabled" @click="login">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    user: '',
    password: '',
    account: {
      user: 'itsupport',
      password: 'H0mer_13'
    }
  }),
  computed: {
    disabled () {
      return this.user === '' || this.password === ''
    }
  },
  methods: {
    login () {
      if (this.user === this.account.user && this.password === this.account.password)
        this.$emit('authentification', true)
    }
  }
}
</script>
